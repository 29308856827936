import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import globalMessages from 'components/globalMessages';
import { getLocales } from 'selectors/root';
import { getLocale } from 'selectors/user';
import { setLocale } from 'actions/user';
import MoreIcon from 'components/Icons/MoreIcon';
import {
  LanguageSelectionContainer,
  LanguageOptions,
  LanguageButton,
  LanguageSelectPrompt,
  OtherLanguageButton,
} from './styles';
import type { LanguageSelectProps } from './types';
import { openSelectLanguageAlert } from '../../actions/UI';

export const LanguageSelect = ({
  hide,
  languages,
  openLanguageDialogue,
  setLocale,
  userLocale,
}: LanguageSelectProps) => {
  if (languages.length <= 1) return null;

  const selectedLanguage = languages.find(language => language.code === userLocale);
  const secondLanguage = languages[selectedLanguage === languages[0] ? 1 : 0];

  return (
    <LanguageSelectionContainer hide={hide}>
      <LanguageSelectPrompt>
        <FormattedMessage {...globalMessages.selectLanguage} />
      </LanguageSelectPrompt>
      <LanguageOptions>
        <LanguageButton selected={true}>{selectedLanguage.label}</LanguageButton>
        <LanguageButton onClick={() => setLocale(secondLanguage.code)}>{secondLanguage.label}</LanguageButton>
        <OtherLanguageButton selected={true} onClick={openLanguageDialogue}>
          <MoreIcon />
        </OtherLanguageButton>
      </LanguageOptions>
    </LanguageSelectionContainer>
  );
};

const mapStateToProps = state => ({
  languages: getLocales(state).toJS(),
  userLocale: getLocale(state),
});

const mapDispatchToProps = dispatch => ({
  openLanguageDialogue: () => dispatch(openSelectLanguageAlert()),
  setLocale: (locale: string) => dispatch(setLocale(locale, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);
