import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.color.secondaryBackground};
  color: ${({ theme }) => theme.color.text};
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextMessage = styled.span`
  padding: 0 5px;
`;

export const ButtonChange = styled.button`
  text-transform: uppercase;
  text-decoration: underline;
`;
