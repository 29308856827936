import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import globalMessages from 'components/globalMessages';
import { setLocale } from 'actions/user';
import { getLocales } from 'selectors/root';
import { getLocale } from 'selectors/user';
import { AlertContent } from 'assets/styles/sharedStyles';
import { BackButton, LanguageSelectButton, LanguageSelectContainer, LanguageSelectMessage } from './styles';
import type { LanguageSelectAlertProps } from './types';

const LanguageSelectAlert = ({ closeAlert, languages, setLocale, userLocale }: LanguageSelectAlertProps) => (
  <AlertContent>
    <LanguageSelectMessage>
      <FormattedMessage {...globalMessages.selectLanguage} />
    </LanguageSelectMessage>
    <LanguageSelectContainer>
      {languages.map(language => (
        <LanguageSelectButton
          key={language.code}
          onClick={() => setLocale(language.code)}
          selected={language.code === userLocale}
        >
          {language.label}
        </LanguageSelectButton>
      ))}
      <BackButton onClick={closeAlert}>
        <FormattedMessage {...globalMessages.back} />
      </BackButton>
    </LanguageSelectContainer>
  </AlertContent>
);

const mapStateToProps = state => ({
  languages: getLocales(state).toJS(),
  userLocale: getLocale(state),
});

const mapDispatchToProps = dispatch => ({
  setLocale: (locale: string) => dispatch(setLocale(locale, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectAlert);
